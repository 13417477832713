import request from '@/utils/request'

export function getShopByName(query) {
  return request({
    url: '/shop/shop/getShopByName',
    method: 'get',
    params: query
  })
}

export function loadOptionsByName(query) {
  return request({
    url: '/shop/shop/loadOptionsByName',
    method: 'get',
    params: query
  })
}

export function getShopList(query) {
  return request({
    url: '/shop/shop/list',
    method: 'get',
    params: query
  })
}

export function delShop(query) {
  return request({
    url: '/shop/shop/remove/' + query,
    method: 'delete'
  })

}
export function banShop(query) {
  return request({
    url: '/shop/shop/ban/' + query,
    method: 'put'
  })

}
export function unsealShop(query) {
  return request({
    url: '/shop/shop/unseal/' + query,
    method: 'put'
  })

}
export function marginShop(query) {
  return request({
    url: '/shop/shop/margin/' + query,
    method: 'put'
  })
}
// 查询店铺基本信息详细
export function getShop(id) {
  return request({
    url: '/shop/shop/' + id,
    method: 'get'
  })
}
export function getShopCountList(query) {
  return request({
    url: '/shop/shop/listCount',
    method: 'get',
    params: query
  })
}

// 修改店铺基本信息
export function updateShop(data) {
  return request({
    url: '/shop/shop',
    method: 'put',
    data: data
  })
}
