<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="位置" prop="location">
          <a-select style="width: 250px" v-model="form.location" placeholder="请输入标签">
            <a-select-option v-for="(item, index) in this.customDict.LocationEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="类型" prop="type">
          <a-select style="width: 250px" v-model="form.type" placeholder="请输入类型">
            <a-select-option v-for="(item, index) in this.customDict.BannerTypeEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="跳转类型" prop="jumpType">
          <a-select style="width: 250px" v-model="form.jumpType" placeholder="请输入标签" @change="changeType">
            <a-select-option v-for="(item, index) in this.customDict.JumpTypeEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-form-model-item label="请绑定店铺" prop="jumpInfo" v-if="isShow2">
            <div @click="handleInfoShop(form.id)">
              <a-button type="dashed" @click="handleInfoShop(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定剧本" prop="jumpInfo" v-if="isShow">
            <div @click="handleInfo(form.id)">
              <a-button type="dashed" @click="handleInfo(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定活动" prop="jumpInfo" v-if="isShow4">
            <div>
              <a-button type="dashed" @click="handleInfoActivity(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定展会" prop="jumpInfo" v-if="isShow5">
            <div>
              <a-button type="dashed" @click="handleInfo5(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定剧本集" prop="jumpInfo" v-if="isShow3">
            <div>
              <a-button type="dashed" @click="handleInfo3(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定用户" prop="jumpInfo" v-if="isShow6">
            <div @click="handleInfoUser(form.id)">
              <a-button type="dashed" @click="handleInfoUser(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请绑定商家" prop="jumpInfo" v-if="isShow7">
            <div @click="handleInfoShop(form.id)">
              <a-button type="dashed" @click="handleInfoShop(form.id)" danger><span
                style="color: dodgerblue"> {{ form.remark }}</span>
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="请输入外联链接" prop="jumpInfo"v-if="isShow9">
            <a-input v-model="form.jumpInfo" placeholder="请输入外联链接"/>
          </a-form-model-item>
        </a-form-model-item>

        <a-form-model-item label="广告名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入广告名称"/>
        </a-form-model-item>

        <a-form-model-item label="高清图" prop="img">
          <a-upload
            name="img"
            listType="picture-card"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="imgAvatarUpload">
            <img
              v-if="form.img"
              :src="form.img"
              alt="img"
              style="height: 102px; width: 102px; border-radius: 50%"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="背景色" prop="backgroundColor">
          <a-input v-model="form.backgroundColor" placeholder="请输入背景色"/>
        </a-form-model-item>

        <a-form-model-item label="小图" prop="smallImg">
          <a-upload
            name="smallImg"
            listType="picture-card"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="smallImgAvatarUpload">
            <img
              v-if="form.smallImg"
              :src="form.smallImg"
              alt="smallImg"
              style="height: 102px; width: 102px; border-radius: 50%"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <!--      <a-form-model-item label="小图" prop="smallImg">
                <a-upload
                  name="smallImg"
                  v-model="fileImgList"
                  listType="picture-card"
                  :fileList="fileImgList"
                  :remove="removeFileImgListFile"
                  class="avatar-uploader"
                  :multiple="true"
                  :show-upload-list="true"
                  :preview="handlePreview"
                  :before-upload="beforeOssUpload"
                  :customRequest="coverSmallImgUploadInfo">
                  <div>
                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                    <div class="ant-upload-text">上传</div>
                  </div>
                </a-upload>
              </a-form-model-item>-->

<!--        <a-form-model-item label="音频(可上传多个)" prop="audio">
          <a-upload
            name="audio"
            v-model="talkInfoVideo"
            listType="picture-card"
            :fileList="talkInfoVideo"
            :remove="removeVideoFile"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="true"
            :preview="handleTalkVideoPreview"
            :before-upload="beforeTalkVideoOssUpload"
            :customRequest="coverTalkVideoAvatarUploadInfo">
            <div>
              <a-icon :type="talkVideoLoading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>-->
        <a-form-model-item label="音频" prop="audio">
          <OssMultiUploadSingle v-model="form.audio" type="video" :maxSize="200"></OssMultiUploadSingle>
        </a-form-model-item>
        <!--        <a-form-model-item label="备注" prop="remark">
                  <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear/>
                </a-form-model-item>-->
        <a-form-model-item label="开始时间" prop="startTime">
          <a-date-picker style="width: 100%" @change="onDateChange" :showTime="true" format="YYYY-MM-DD HH:mm:ss"
                         allow-clear/>
        </a-form-model-item>
        <a-form-model-item label="结束时间" prop="endTime">
          <!--        <a-date-picker style="width: 100%" v-model="form.endTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
          <a-date-picker style="width: 100%" @change="onDateChange1" :showTime="true" format="YYYY-MM-DD HH:mm:ss"
                         allow-clear/>
        </a-form-model-item>

        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <index-script-modal ref="IndexScriptModal" @select="indexScriptModalSelect"
                        @selectName="indexScriptModalName"></index-script-modal>
    <index-expo-modal ref="IndexExpoModal" @select="indexExpoModalSelect"
                      @selectname="indexExpoModalName"></index-expo-modal>
    <shop-list-modal ref="ShopListModal" @select="shopListModalSelect"
                     @selectname="shopListModalSelectName"></shop-list-modal>
    <sheet-list-modal ref="SheetListModal" @select="sheetListModalSelect"
                      @selectname="sheetListModalSelectName"></sheet-list-modal>
    <activity-list-modal ref="ActivityListModal" @select="activityListModalSelect"
                         @selectname="activityListModalSelectName"></activity-list-modal>
    <user-list-modal ref="UserListModal" @select="userListModalSelect"
                     @selectname="userListModalSelectName"></user-list-modal>
  </div>
</template>

<script>
import {getBanner, addBanner, updateBanner} from '@/api/config/banner'
import {mapGetters} from 'vuex'
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import IndexScriptModal from "@/components/script/IndexScriptModal";
import IndexExpoModal from "@/views/expo/expoScript/modules/indexExpoModal";
import ShopListModal from "@/views/shop/shopList/ShopListModal";
import SheetListModal from "@/views/script/sheet/SheetListModal";
import ActivityListModal from "@/views/activity/activity/ActivityListModal";
import UserListModal from "@/views/user/user/UserListModal";
import OssMultiUploadSingle from "@/views/script/script/modules/OssMultiUploadSingle";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    IndexExpoModal,
    ShopListModal,
    SheetListModal,
    UserListModal,
    ActivityListModal,
    OssMultiUploadSingle,
    IndexScriptModal
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      fileList: [],
      fileImgList: [],
      talkInfoVideo: [],
      isShow: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      isShow6: false,
      isShow7: false,
      isShow8: false,
      isShow9: false,
      // 表单参数
      form: {
        id: null,

        location: null,

        type: null,

        name: null,

        img: null,

        backgroundColor: null,

        smallImg: null,

        audio: null,

        startTime: null,
        endTime: null,

        jumpType: null,

        jumpInfo: null,

        jumpInfoName: null,  //1

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        location: [
          {required: true, message: 'banner位置 1:启动页,2:首页轮播图,3:每日弹窗不能为空', trigger: 'blur'}
        ],

        type: [
          {required: true, message: '类型 1:精选,2:活动,3:展会不能为空', trigger: 'change'}
        ],

        name: [
          {required: true, message: '广告名称不能为空', trigger: 'blur'}
        ],

        img: [
          {required: true, message: '高清图不能为空', trigger: 'blur'}
        ],
        isDeleted: [
          {required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur'}
        ],
        jumpType: [
          {required: true, message: '跳转类型不能为空', trigger: 'blur'}
        ],
        jumpInfo: [
          {required: true, message: '跳转类型绑定不能为空', trigger: 'blur'}
        ],
        startTime: [
          {required: true, message: '开始时间不能为空', trigger: 'blur'}
        ],

        endTime: [
          {required: true, message: '结束时间不能为空', trigger: 'blur'}
        ],
        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.talkInfoImage = []
      this.talkInfoVideo = []
      this.fileList = []
      this.fileImgList = []
      this.isShow = null
      this.isShow1 = null
      this.isShow2 = null
      this.isShow3 = null
      this.isShow4 = null
      this.isShow5 = null
      this.isShow6 = null
      this.isShow7 = null
      this.isShow8 = null
      this.isShow9 = null
      this.form = {
        id: null,

        location: null,

        type: null,

        name: null,

        img: null,

        backgroundColor: null,

        smallImg: null,

        audio: null,
        startTime: null,
        endTime: null,
        jumpType: null,

        jumpInfo: null,

        jumpInfoName: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.isShow = null
      this.isShow1 = null
      this.isShow2 = null
      this.isShow3 = null
      this.isShow4 = null
      this.isShow5 = null
      this.isShow6 = null
      this.isShow7 = null
      this.isShow8 = null
      this.isShow9 = null
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    // 删除图片
    removeFileImgListFile(file) {
      var fileImgList = []
      this.fileImgList.forEach(e => {
        if (e.url !== file.url) {
          fileImgList.push(e)
        }
      })
      this.fileImgList = fileImgList
      var info = ''
      fileImgList.forEach(e => {
        debugger
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'smallImg', info)
      console.log('info=', _this.form.smallImg)
    },
    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.form.startTime = dateString
    },
    onDateChange1(date, dateString) {
      console.log(date, dateString)
      this.form.endTime = dateString
    },
    changeType(value) {
      console.log("123" + value);
      debugger
      this.isShow = false;
      this.isShow1 = false
      this.isShow2 = false
      this.isShow3 = false
      this.isShow4 = false
      this.isShow5 = false
      this.isShow6 = false
      this.isShow7 = false
      this.isShow8 = false
      this.isShow9 = false
      if (value == 0) {
        debugger
        this.isShow = true;
        this.form.remark = "绑定剧本"
      } else if (value == 1) {
        this.isShow1 = true;
        this.form.remark = ""
      } else if (value == 2) {
        this.isShow2 = true;
        this.form.remark = "请绑定店铺"
      } else if (value == 3) {
        this.isShow3 = true;
        this.form.remark = "请选择剧本集"
      } else if (value == 4) {
        this.isShow4 = true;
        this.form.remark = "请选择活动"
      } else if (value == 5) {
        this.isShow5 = true;
        this.form.remark = "绑定展会"
      } else if (value == 6) {
        this.isShow6 = true;
        this.form.remark = "请选择用户"
      } else if (value == 7) {
        this.isShow7 = true;
        this.form.remark = "请绑定商家"
      } else if (value == 8) {
        this.isShow8 = true;
        this.form.remark = ""
      } else if (value == 9) {
        this.isShow9 = true;
        this.form.remark = ""
      }

    },
    handleInfoShop: function (id) {
      this.$refs.ShopListModal.open();
    },
    handleInfoActivity: function (id) {
      this.$refs.ActivityListModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    handleInfo: function (id) {
      this.$refs.IndexScriptModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    handleInfo5: function (id) {
      this.$refs.IndexExpoModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },

    handleInfo3: function (id) {
      this.$refs.SheetListModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    indexScriptModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    indexScriptModalName(scriptName) {
      this.$set(this.form, 'jumpInfoName', scriptName)
      this.$set(this.form, 'remark', scriptName)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的scriptName", scriptName, "123", this.form);
    },
    indexExpoModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    indexExpoModalName(expoName) {
      this.$set(this.form, 'jumpInfoName', expoName)
      this.$set(this.form, 'remark', expoName)
    },

    //剧本集
    sheetListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    //剧本集
    sheetListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的剧本集", name, "123", this.form);
    },

    //活动
    activityListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    //活动
    activityListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的活动", name, "123", this.form);
    },
    //店铺信息
    shopListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    shopListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的店铺", name, "123", this.form);
    },
    //用户信息
    handleInfoUser: function (id) {
      this.$refs.UserListModal.open();
    },
    userListModalSelect(ids) {
      this.form.jumpInfo = ids;
      console.log("获取到的ids", ids);
    },
    userListModalSelectName(name) {
      this.$set(this.form, 'jumpInfoName', name)
      this.$set(this.form, 'remark', name)
      // this.form.jumpInfoName =scriptName;
      console.log("获取到的用户", name, "123", this.form);
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner(id).then(response => {
        Object.assign(this.form, response.data)
        this.open = true
        this.formTitle = '修改'

        // 处理图片反显
        if (response.data.smallImg !== undefined && response.data.smallImg !== null && JSON.stringify(response.data.smallImg) !== '"{}"') {
          var images = this.form.smallImg.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileImgList.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'smallImg.jpg'})
          }
        }
        if (response.data.audio !== undefined && response.data.audio !== null) {
          var videos = this.form.audio.split(',')
          for (var e = 0; e < videos.length; e++) {
            this.talkInfoVideo.push({status: 'done', url: videos[e], uid: this.getUidRandom(), name: 'audio.mp4'})
          }
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    smallImgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'sript'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'smallImg', res)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'sript'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'img', res)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传详情覆盖默认的上传行为
    imgAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'script'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'image.jpg'})
          var info = ''
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'info', info)
          console.log('info=', _this.form.info)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束

    // oss 上传开始-视频
    removeVideoFile(file) {
      var talkInfoVideo = []
      this.talkInfoVideo.forEach(e => {
        if (e.url !== file.url) {
          talkInfoVideo.push(e)
        }
      })
      this.talkInfoVideo = talkInfoVideo
      var info = ''
      talkInfoVideo.forEach(e => {
        debugger
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'audio', info)
      console.log('info=', _this.form.audio)
    },
    async handleTalkVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewTalkImage = file.url || file.preview
      this.previewTalkVisible = true
    },
    // 上传之前校验
    beforeTalkVideoOssUpload(file) {
      //todo
      // const isJPG = file.type === 'video/mp4'
      // if (!isJPG) {
      //   this.$message.error('上传视频只能是 MP4 格式!')
      //   return false
      // }
      const isLt2M = file.size / 1024 / 1024 < 200
      if (!isLt2M) {
        this.$message.error('上传视频大小不能超过 200MB!')
        return false
      }
      return isLt2M
    },
    coverTalkVideoAvatarUploadInfo(file, index) {
      console.log('获取文件对象', file.file)
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'banner'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.talkInfoVideo.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'video.mp4'})
          var info = ''
          _this.talkInfoVideo.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'audio', info)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'script'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileImgList.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'smallImg.jpg'})
          var info = ''
          _this.fileImgList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'smallImg', info)
          console.log('info=', _this.form.smallImg)
          _this.$message.success('上传成功')
        })
      })
    },
// 上传详情覆盖默认的上传行为
    coverSmallImgUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'script'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileImgList.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'smallImg.jpg'})
          var info = ''
          _this.fileImgList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'smallImg', info)
          console.log('info=', _this.form.smallImg)
          _this.$message.success('上传成功')
        })
      })
    },
  }
}
</script>
