import request from '@/utils/request'

// 查询展会列表
export function listExpo(query) {
  return request({
    url: '/expo/expo/list',
    method: 'get',
    params: query
  })
}

// 查询展会详细
export function getExpo(id) {
  return request({
    url: '/expo/expo/' + id,
    method: 'get'
  })
}

// 新增展会
export function addExpo(data) {
  return request({
    url: '/expo/expo',
    method: 'post',
    data: data
  })
}

// 修改展会
export function updateExpo(data) {
  return request({
    url: '/expo/expo',
    method: 'put',
    data: data
  })
}

// 删除展会
export function delExpo(id) {
  return request({
    url: '/expo/expo/' + id,
    method: 'delete'
  })
}
